import React from "react";
import loadable from "@loadable/component";
import ogImage from "../../images/global_assets/og-image.png";
import LpLayout from "../../components/Layout/lp-layout";
import SEO from "../../components/seo";
import Hero from "../../components/Hero/hero";
import {
  ReserveLPHero,
  ReserveLPWhiteGlove,
  ReserveLPLargeFeatures,
  ReserveLPCTA,
  ReserveCsSlides,
  ReserveLpSuccess,
  ReserveLPTestimonials,
} from "../../data/landing/reserve-lp-data";

// import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
// import Badges from "../../components/ConversionPath/Badges";
// import LargeFeatures from "../../components/LargeFeatures/LargeFeatures";
// import WhiteGlove from "../../components/WhiteGlove/WhiteGlove";
// import LargeCta from "../../components/CTAs/LargeCta";

const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const Badges = loadable(() => import("../../components/ConversionPath/Badges"));
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));

const whiteGloveImport = "about-us-white-glove.png";

const ReserveLp = () => {
  return (
    <LpLayout phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="SpotOn Reserve Demo | Point-of-Sale"
        description="Run and grow your business more efficiently than ever with a powerful reservation and table management"
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />

      <Hero sectionData={ReserveLPHero} circleBg={false} />
      <section style={{ marginTop: "40px" }}>
        <Badges />
      </section>

      <section style={{ marginTop: "60px" }}>
        {/* <SuccessStories sectionData={ReserveLpSuccess} slides={ReserveCsSlides} /> */}
        <TestmonialReviews sectionData={ReserveLPTestimonials} />
      </section>

      {/* <PartnerLogos /> */}

      <LargeFeatures
        sectionData={ReserveLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />

      <WhiteGlove
        sectionData={ReserveLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />

      <LargeCta sectionData={ReserveLPCTA} />
    </LpLayout>
  );
};

export default ReserveLp;
